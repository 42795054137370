body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #dfdfe6;
  border-radius: 4px;
  cursor: pointer;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #cccce9;
  border-radius: 4px;
  cursor: pointer;
}
::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}
::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

@media only screen and (max-width: 900px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
